import { BrowserRouter, Route, Routes } from "react-router-dom";

import AccountView from "./AccountView";
import MatchesView from "./MatchesView";
import TournamentsView from "./TournamentsView";
import BottomNavigationMenu from "../components/BottomNavigationMenu";
import Header from "../components/Header";
import Box from "@mui/material/Box";

function LoggedInView() {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Box marginTop="1em" height="100%">
                    <Routes>
                        <Route path="/" element={<AccountView />} />
                        <Route path="/tournaments" element={<TournamentsView />} />
                        <Route path="/matches" element={<MatchesView />} />
                    </Routes>
                </Box>
                <BottomNavigationMenu />
            </BrowserRouter>
        </>
    )
}

export default LoggedInView;

