import { Badge } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NotificationsIcon from '@mui/icons-material/Notifications';

function Header() {
    return (
        <AppBar position="static" color="transparent" style={{ boxShadow: "none" }}>
            <Toolbar>
                <Typography
                    variant="h6"
                    noWrap
                    component="div">
                    CryptoMadness
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                    <IconButton
                        size="large"
                        aria-label="show notifications"
                        color="inherit">
                        <Badge badgeContent={1} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header;