import { useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import HomeIcon from '@mui/icons-material/Home';
import TournamentIcon from '@mui/icons-material/EmojiEvents';
import MatchesIcon from '@mui/icons-material/SportsKabaddi';


const BottomNavigationMenu = () => {
    const [value, setValue] = useState(0);

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}>
                <BottomNavigationAction label="Home" icon={<HomeIcon />} component={RouterLink} to="/" />
                <BottomNavigationAction label="Tournaments" icon={<TournamentIcon />} component={RouterLink} to="/tournaments" />
                <BottomNavigationAction label="Matches" icon={<MatchesIcon />} component={RouterLink} to="/matches" />
            </BottomNavigation>
        </Paper>
    )
}

export default BottomNavigationMenu;