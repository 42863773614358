import { Box, Button, Container, Typography } from "@mui/material";
import { useBalance } from 'wagmi'
import { usePrivy } from "@privy-io/react-auth";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";

import BottomNavigationMenu from "../components/BottomNavigationMenu";

function AccountView() {
    const { logout, user } = usePrivy();
    const { wallet: activeWallet } = usePrivyWagmi();

    console.log(user);

    console.log(activeWallet);

    const { data, isError, isLoading } = useBalance({
        address: activeWallet?.address as any
    })

    return (
        <Container maxWidth="xl" style={{ height: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "strech", flexDirection: "column" }}>
            <Box display="flex" flexDirection="column" gap={"1em"} textAlign="center">
                <Typography variant="h6">Welcome</Typography>
                {user?.google?.name && <Typography variant="h6">{user?.google?.name}</Typography>}
                {user?.twitter?.name && <Typography variant="h6">{user?.twitter?.name}</Typography>}
                <Typography variant='body2'>{user?.wallet?.address}</Typography>
                <Typography variant='body2'>Balance: {data?.formatted} {data?.symbol}</Typography>
                <Button variant='outlined'>Deposit</Button>
                <Button variant="outlined">Withdraw</Button>
            </Box>
            <Button variant="contained" onClick={logout}>Log Out</Button>

            <BottomNavigationMenu />
        </Container>
    )
}

export default AccountView;
