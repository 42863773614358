import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrivyProvider } from '@privy-io/react-auth';
import { PrivyWagmiConnector } from '@privy-io/wagmi-connector';
import { configureChainsConfig, defaultChain } from './config/wagmiConfig';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';

import "./index.css";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID || ""}
      config={{
        defaultChain: defaultChain,
        supportedChains: [defaultChain],
        embeddedWallets: {
          createOnLogin: 'all-users'
        },
        loginMethods: ['email', 'google', 'twitter'],
        appearance: {
          theme: 'light',
          accentColor: '#676FFF'
        },
        // TODO:Configure your app's legal policies
        // legal: {
        //   termsAndConditionsUrl: 'https://your-terms-and-conditions-url',
        //   privacyPolicyUrl: 'https://your-privacy-policy-url'
        // }
      }}>
      <PrivyWagmiConnector wagmiChainsConfig={configureChainsConfig}>
        <App />
      </PrivyWagmiConnector>
    </PrivyProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
