import { usePrivy } from '@privy-io/react-auth';

import SignInView from './views/SignIn';
import LoggedInView from './views/LoggedIn';
import LoadingView from './views/LoadingView';

function App() {
  const { authenticated, ready } = usePrivy();

  if (!ready) {
    return <LoadingView />
  }

  if (!authenticated) {
    return (<SignInView />)
  }


  return (<LoggedInView />)

}

export default App;
