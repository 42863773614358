import React, { ChangeEvent, useState } from "react";
import { Box, Container, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import TabPanel from "../components/TabPanel";

function TournamentsView() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // Search bar
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="1em">

                <Typography component="p" fontSize={"1.1em"}>Tournaments</Typography>

                <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Box>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Public" style={{ textTransform: 'capitalize' }} />
                        <Tab label="Hosted" style={{ textTransform: 'capitalize' }} />
                        <Tab label="Participating" style={{ textTransform: 'capitalize' }} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        Public
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Hosted
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Participating
                    </TabPanel>
                </Box>

            </Box>
        </Container>
    )
}

export default TournamentsView;