import CircularProgress from '@mui/material/CircularProgress';
import CenteredLayout from '../components/CenteredLayout';

function LoadingView() {
    return (
        <CenteredLayout>
            <CircularProgress />
        </CenteredLayout>
    )
}

export default LoadingView;
