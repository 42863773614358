import { ChangeEvent, useState } from "react";
import { Box, Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Search from "@mui/icons-material/Search";

function MatchesView() {
    // Search bar
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Container>
            <Box display="flex" flexDirection="column" gap="1em">
                <Typography component="p" fontSize={"1.1em"}>Matches</Typography>

                <TextField
                    size="small"
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Container>
    )
}

export default MatchesView;