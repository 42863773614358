import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { usePrivy } from '@privy-io/react-auth';

import CenteredLayout from '../components/CenteredLayout';

function SignInView() {
    const { login } = usePrivy();

    return (
        <CenteredLayout>
            <Typography variant='h5'>CryptoMadness</Typography>
            <Typography variant='body2'>The crypto social app for quiz tournaments</Typography>
            <Button variant='contained' onClick={login}>Log in</Button>
        </CenteredLayout>
    )
}

export default SignInView;
