import { Box, Container } from "@mui/material";

interface CenteredLayoutProps {
    children: React.ReactNode;
}

function CenteredLayout({ children }: CenteredLayoutProps) {
    return (
        <Container maxWidth="xl" style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box display="flex" flexDirection="column" gap={"1em"} textAlign="center">
                {children}
            </Box>
        </Container>
    )
}

export default CenteredLayout;